<template>
	<div id="nav" class="w-100">
		<router-link to="/">
			<b-img left alt="Zurück" src="../static/back_arrow_2.png" class="icon-style"></b-img>
		</router-link>
		<div class="status-div">
			<b>BLE Status: <span :style="{color: bleStatusColor}">{{bleStatusText}}</span></b>
		</div>
	</div>
</template>

<script>
export default {
	name: "AppLayoutNav",

	data() {
		return {
			
		}
	},

	computed: {
		bleStatusColor() {
			if(this.$store.state.bleConnected) return 'blue'
			return 'red'
		},
		bleStatusText() {
			if(this.$store.state.bleConnected) return 'Connected'
			return 'NOT connected'
		}
	}
}
</script>

<style scoped>
#nav {
	/*align: left;*/
}

.icon-style {
	width: 50px;
	height: 50px;
	padding: 3px 3px 3px 3px;
}

.status-div {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}
</style>